import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';

const GalleryPage = () => {
  return (
    <Layout>
      <SEO title="Gallery" />
      <div className="container ai-gap--lg ai-align-middle ai-gallery">
        <div className="row ai-gap--md">
          <div className="col-xs-12 ai-gap--sm">
            <h1 className="ai-gallery-title">Gallery is still Work In Progress.</h1>
            <p>
              To check all my works to date, go ahead to my ArtStation page. There you will find all
              my Illustrations, Animations... etc.
            </p>
            <p>Sorry for the inconvenience.</p>
          </div>
          <div className="col-xs-12">
            <div>
              <a
                className="ai-gallery-link-logo"
                href="https://www.artstation.com/alexillustration"
              >
                <img src="/Artstationlogo.svg" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default GalleryPage;
